import Close from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface VoidDocumentModalProps {
  voidDialogOpen: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

export function VoidDocumentModal({ voidDialogOpen, onClose, onConfirmed }: VoidDocumentModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="void-document-modal"
      sx={(theme) => ({ '& .MuiDialog-paper': { borderRadius: theme.shape.borderRadius / 3, padding: theme.spacing(1) } })}
      open={voidDialogOpen}
    >
      <DialogTitle>
        {t('pipeline.tabs.document_list.more_actions.void_document_confirmation_title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{t('pipeline.tabs.document_list.more_actions.void_document_confirmation_message')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={(theme) => ({
            '&:hover': { backgroundColor: theme.palette.grey[500] },
            textTransform: 'none',
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.5, 2),
          })}
          autoFocus
          onClick={onClose}
        >
          {t('pipeline.tabs.document_list.more_actions.void_document_confirmation_cancel_button')}
        </Button>
        <Button
          sx={(theme) => ({
            '&:hover': { backgroundColor: theme.palette.grey[500] },
            textTransform: 'none',
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.5, 2),
          })}
          onClick={onConfirmed}
        >
          {t('pipeline.tabs.document_list.more_actions.void_document_confirmation_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
