import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';
import { type Document } from 'services/documents/entities/Document';

interface UseVoidDocumentProps {
  documentId: string;
  documentStatus: Document['status'];
  onVoidSuccess: () => Promise<void>;
  onVoidFailure: () => Promise<void>;
}

const editorRepository = new ApiEditorRepository();

const statusesThatCanBeVoided: Document['status'][] = ['Won', 'Lost', 'Awaiting Signature'];

export function useVoidDocument({ documentId, documentStatus, onVoidSuccess, onVoidFailure }: UseVoidDocumentProps) {
  const [isVoidable, setIsVoidable] = useState(false);

  useEffect(() => {
    if (statusesThatCanBeVoided.includes(documentStatus)) {
      setIsVoidable(true);
    } else {
      setIsVoidable(false);
    }
  }, [documentStatus]);

  const { mutate } = useMutation({
    mutationFn: async () => {
      return editorRepository.voidDocument(documentId);
    },
    onSuccess: onVoidSuccess,
    onError: () => onVoidFailure(),
  });

  return { isVoidable, voidDocument: mutate };
}
