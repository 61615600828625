import { v4 as uuidv4 } from 'uuid';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { defaultRowMeta, defaultColumnMeta, initialColumnWidth, pricingRowsMeta, initialPricingColumnWidth } from './variables';
import { TableColumnType, TableTypeIdentifier } from '../../../grid/reduxStore/saveHandlers';

export const columns: TableColumnType[] = [
  { field: 'id', headerName: 'ID', width: 90, editable: false },
  {
    ...defaultColumnMeta,
    field: 'column1',
    width: initialColumnWidth,
  },
  {
    ...defaultColumnMeta,
    field: 'column2',
    width: initialColumnWidth,
  },
];

export const rows = [
  { id: uuidv4(), column1: 'Text', column2: 'Text', rowType: TableRowTypes().HEADER, ...defaultRowMeta },
  { id: uuidv4(), column1: 'Enter text here...', column2: 'Or add a new row...', rowType: TableRowTypes().BODY, ...defaultRowMeta },
  {
    id: uuidv4(),
    column1: 'Maybe change the row type!',
    column2: 'Or add another column!',
    rowType: TableRowTypes().BODY,
    ...defaultRowMeta,
  },
];

export const pricingColumns: TableColumnType[] = [
  { field: 'id', headerName: 'ID', width: 90, editable: false },
  {
    ...defaultColumnMeta,
    field: 'column1',
    width: initialPricingColumnWidth,
  },
  {
    ...defaultColumnMeta,
    field: 'column2',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
  },
  {
    ...defaultColumnMeta,
    field: 'column3',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
  },
  {
    ...defaultColumnMeta,
    field: 'column4',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
  },
];

export const setOfRowsForPricingTable = [
  {
    id: uuidv4(),
    column1: 'Subtotal',
    rowType: TableRowTypes(true)['TOTAL'],
    ...pricingRowsMeta.defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Tax',
    rowType: TableRowTypes(true)['TOTAL'],
    ...pricingRowsMeta.defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Discount',
    rowType: TableRowTypes(true)['TOTAL'],
    ...pricingRowsMeta.defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Totals',
    rowType: TableRowTypes(true)['TOTAL'],
    ...pricingRowsMeta.defaultRowMeta,
  },
];

export const pricingRows = [
  {
    id: uuidv4(),
    column1: 'Name',
    column2: 'Quantity',
    column3: 'Price',
    column4: 'Subtotal',
    rowType: TableRowTypes().HEADER,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    rowType: TableRowTypes().BODY,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    rowType: TableRowTypes().BODY,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    rowType: TableRowTypes().BODY,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    rowType: TableRowTypes().BODY,
    ...defaultRowMeta,
  },
  ...setOfRowsForPricingTable,
];

export const defaultTableContent = {
  rows,
  columns,
  metadata: {
    tableType: TableTypeIdentifier.TEXT_TABLE,
  },
};

export const defaultPricingTableContent = {
  rows: pricingRows,
  columns: pricingColumns,
  metadata: {
    tableType: TableTypeIdentifier.PRICING_TABLE,
  },
};
