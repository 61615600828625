import { TableSettings } from '../../../component';
import SvgIcoTableColumnAddAfter from 'components/icon/icons/IcoTableColumnAddAfter';
import IcoTableColumnAddBefore from 'components/icon/icons/IcoTableColumnAddBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';
import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { TableRColumnTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { useTableColumnManipulation } from './useTableColumnManipulation';
import { TableColumnAddPositions } from '../types';
import { setActiveTableSettingsPanel } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { useSelector } from 'react-redux';
import { RootState } from 'components/editor/grid/reduxStore/Store';
import { TableSettingsTypes } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
const ColumnSettings = () => {
  const dispatch = useAppDispatch();
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const { t } = useTranslation();
  const { handleColumnAdd, handleColumnDuplication, handleColumnDeletion } = useTableColumnManipulation();
  const [columnType, setColumnType] = useState<string>(TableRColumnTypes.TEXT);

  const handleColumnTypeChange = (event: SelectChangeEvent) => {
    if (activeTableSettingsPanel !== TableSettingsTypes.TABLE_COLUMNS) return;
    const columnType: string = event.target.value;
    setColumnType(columnType);
  };

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const buttons = [
    { icon: <SvgIcoTableColumnAddAfter />, arialLabel: 'column-add-after', fn: () => handleColumnAdd(TableColumnAddPositions.AFTER) },
    { icon: <IcoTableColumnAddBefore />, arialLabel: 'column-add-before', fn: () => handleColumnAdd(TableColumnAddPositions.BEFORE) },
    { icon: <SvgIcoContentCopy />, arialLabel: 'content-copy', fn: handleColumnDuplication },
    { icon: <SvgIcoTrash />, arialLabel: 'trash', fn: handleColumnDeletion },
  ];

  return (
    <TableSettings
      iconButtons={buttons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleColumnTypeChange}
      helpText={t('document.grid.table.setting.update_select_column_type')}
      panelTitle={t('document.grid.table.setting.column_type')}
      selectId="select-column-type"
      selectOptions={TableRColumnTypes}
      selectValue={columnType}
      menuTitle={t('document.grid.table.setting.columns')}
      testId="table-column-settings"
    />
  );
};

export default ColumnSettings;
