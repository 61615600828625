import { SelectChangeEvent } from '@mui/material/Select';
import { useContext, useEffect, useState } from 'react';
import { SidePanelContextRowsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { TableColumnType, TableType } from '../../../../grid/reduxStore/saveHandlers';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { getSanitizedDataForTableFooter } from '../../../../../../utils/getSanitizedDataForTableFooter';

export const useTableRowTypeChange = () => {
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextRowsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const { getTableData } = useTableManipulation();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const [rowType, setRowType] = useState<string>('');

  useEffect(() => {
    if (!toggledTableSettingsPanel) return;

    const selectedModel = toggledTableSettingsPanel.tableApi.selectedModel;
    setRowType(selectedModel.row.rowType);
  }, [toggledTableSettingsPanel]);

  const focusIntoCell = (selectedRowType: string) => {
    if (!toggledTableSettingsPanel) return;

    const selectedModel = toggledTableSettingsPanel.tableApi.selectedModel;
    const rowId = selectedModel.row.id;
    let fieldToFocus = selectedModel.field;

    if (selectedRowType === TableRowTypes().FOOTER || !selectedModel.isEditable) {
      const firstEditableColumn = toggledTableSettingsPanel?.tableApi?.tableCallbackDetails?.api.getAllColumns().find((column) => {
        if (column.editable) return column.field;
      });

      fieldToFocus = (firstEditableColumn as TableColumnType)?.field;
    }
    if (rowId && fieldToFocus) {
      toggledTableSettingsPanel?.tableApi.tableCallbackDetails?.api.startCellEditMode({
        id: rowId,
        field: fieldToFocus,
      });
    }
  };

  const handleRowTypeChange = async (event: SelectChangeEvent) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!selectedSectionId) throw new Error('Section id cannot be empty');

    const tableData = getTableData(selectedBlockIdByWrapper, selectedSectionId) as TableType;
    const selectedRowType = event.target.value as string;
    const { row: selectedRow } = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;

    const updateRow = (row: typeof selectedRow, newRowData: Partial<typeof selectedRow>) => ({
      ...row,
      ...newRowData,
      rowType: selectedRowType,
    });

    const updatedRows = tableData.rows.map((row) => {
      if (row.id !== selectedRow.id) return row;

      if (selectedRowType === TableRowTypes().FOOTER) {
        const sanitizedData = getSanitizedDataForTableFooter(row, tableData.columns);
        return updateRow(row, sanitizedData);
      }

      if (row.type === TableRowTypes().FOOTER) {
        const resetData = Object.keys(selectedRow).reduce((acc, key) => {
          acc[key] = key === 'id' || key === '__reorder__' ? selectedRow[key] : '';
          return acc;
        }, {});
        return updateRow(row, resetData);
      }

      return updateRow(row, {});
    });

    await blockContentChangedHandler(selectedBlockIdByWrapper, selectedSectionId, {
      ...tableData,
      rows: updatedRows,
    });

    setRowType(selectedRowType);
    focusIntoCell(selectedRowType);
  };

  return {
    rowType,
    handleRowTypeChange,
  };
};
