import { useBillingInfoApiData } from 'hooks/useBillingInfoApiData';
import { useLocationData } from 'hooks/useLocationData';
import { createContext, useState } from 'react';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { BillingEntity } from 'services/api/account/interfaces';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { CountryListing, LocationDataEntity, StateListing } from 'services/api/listings/interfaces';
import { PlanRepository } from 'services/api/plan/PlanRepository';

interface PlanSummaryProviderProps {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  setApiErrors: (errors: string[]) => void;
  children: React.ReactNode;
}

type PlanSummaryContextProps = {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  currentPaymentMethod: PaymentMethodData | null;
  setApiErrors: (errors: string[]) => void;
  setCurrentPaymentMethod: (data: PaymentMethodData | null) => void;
  locationData: LocationDataEntity | undefined;
  isLocationFetched: boolean;
  getStatesFromCountry: (countryId: string | number, states: StateListing[], countries?: CountryListing[] | undefined) => StateListing[];
  billingData: BillingEntity | undefined;
  isBillingFetched: boolean;
  isBillingLoading: boolean;
  isBillingRefetching: boolean;
  refetchBilling: any;
};

export const PlanSummaryContext = createContext<PlanSummaryContextProps>({} as PlanSummaryContextProps);

export const PlanSummaryProvider = ({
  accountId,
  accountRepository,
  planRepository,
  listingsRepository,
  setApiErrors,
  children,
}: PlanSummaryProviderProps) => {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethodData | null>(null);

  const {
    data: billingData,
    isLoading: isBillingLoading,
    isFetched: isBillingFetched,
    refetch: refetchBilling,
    isRefetching: isBillingRefetching,
  } = useBillingInfoApiData({
    accountRepository,
    accountId,
  });
  const { isFetched: isLocationFetched, locationData, getStatesFromCountry } = useLocationData({ listingsRepository });

  const contextValue: PlanSummaryContextProps = {
    accountId,
    accountRepository,
    planRepository,
    listingsRepository,
    setApiErrors,
    setCurrentPaymentMethod: (data: PaymentMethodData | null) => setCurrentPaymentMethod(data),
    currentPaymentMethod,
    locationData,
    isLocationFetched,
    getStatesFromCountry,
    billingData,
    isBillingFetched,
    isBillingRefetching,
    refetchBilling,
    isBillingLoading,
  };
  return <PlanSummaryContext.Provider value={contextValue}>{children}</PlanSummaryContext.Provider>;
};
