import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Chip,
  Typography,
  Button,
  useTheme,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Container,
  Tooltip,
  Stack,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { InfoOutlined } from '@mui/icons-material';

import { PlanOverviewProps } from './interface';

const PlanOverview: React.FC<PlanOverviewProps> = ({
  planTier,
  title,
  subtitle,
  chipLabel = '',
  topFeatures,
  topFeaturesHeader = '',
  price,
  billingCycle,
  billingModel,
  userMinimum,
  buttonLabel,
  onClick,
  buttonDisabled = false,
  buttonLabelSecondary = '',
  onClickSecondary = null,
  buttonDisabledSecondary = false,
  children = null,
  isDowngrade = false,
  curPlanTier,
}) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation('translation', { useSuspense: false });

  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: '#ffffff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    position: 'relative',
    gap: theme.spacing(3),
    borderRadius: theme.spacing(3),
    alignSelf: 'stretch',
    overflow: 'visible',
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '100px',
    width: '100%',
    boxShadow: 'none',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontWeight: 600,
    textTransform: 'initial',
  }));

  const HorizontalBox = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: theme.spacing(1),
  }));

  const PlanChip = styled(Chip)(() => ({
    position: 'absolute',
    left: '50%',
    top: '0',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
  }));

  const freeOrTrialAndBusiness = () => {
    if (planTier == 'business') {
      return curPlanTier != 'free' && curPlanTier != '' ? true : false;
    }
    return true;
  };

  const showBookADemo = () => {
    if (planTier == 'business') {
      return curPlanTier == 'free' || curPlanTier == '' ? true : false;
    }
    return false;
  };

  const showUserMinimum = userMinimum && (curPlanTier == 'free' || curPlanTier == '');
  return (
    <Item
      sx={{
        minWidth: '285px',
        width: '100%',
      }}
      data-testid={`plan-overview-${planTier}`}
    >
      {t(chipLabel) && <PlanChip label={t(chipLabel)} color="secondary" />}
      <Typography variant="h4">{t(title)}</Typography>
      <Typography
        variant="body2"
        sx={{
          marginTop: theme.spacing(1),
          minHeight: theme.spacing(9),
        }}
      >
        {t(subtitle)}
      </Typography>
      <HorizontalBox disableGutters>
        <Typography variant="h5" color="primary.light">
          <Box
            component="span"
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary,
            }}
          >
            {t(price)}
          </Box>{' '}
          {t(billingModel)}
        </Typography>
      </HorizontalBox>
      <HorizontalBox disableGutters sx={{ minHeight: theme.spacing(6) }}>
        <Typography variant="body2" color="primary.light">
          {showUserMinimum && (
            <Box
              component="span"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
              }}
            >
              {userMinimum}{' '}
            </Box>
          )}
          {t(billingCycle)}
        </Typography>
      </HorizontalBox>

      <Stack
        direction="column"
        sx={{
          margin: theme.spacing(2, 0, 3),
          gap: 1,
        }}
      >
        {freeOrTrialAndBusiness() && (
          <StyledButton
            disabled={buttonDisabled}
            size="large"
            variant={isDowngrade ? 'outlined' : 'contained'}
            color="primary"
            onClick={() => onClick(planTier)}
          >
            {t(`${buttonLabel}`)}
          </StyledButton>
        )}

        {showBookADemo() && onClickSecondary && buttonLabelSecondary && (
          <StyledButton disabled={buttonDisabledSecondary} size="large" variant="outlined" color="primary" onClick={onClickSecondary}>
            {t(`${buttonLabelSecondary}`)}
          </StyledButton>
        )}
      </Stack>

      {topFeaturesHeader && (
        <Typography component="div" variant="body2" color="primary" sx={{ fontWeight: 600 }}>
          {t(topFeaturesHeader)}
        </Typography>
      )}
      <List>
        {topFeatures?.map((listItem, key) => (
          <ListItem
            sx={{
              padding: 0,
            }}
            key={key}
            secondaryAction={
              i18n.exists(`${listItem}_tooltip`) && (
                <Tooltip title={t(`${listItem}_tooltip`)} sx={{ backgroundColor: '#000000' }}>
                  <IconButton sx={{ padding: 0 }}>
                    <InfoOutlined sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ListItemIcon>
              <CheckIcon sx={{ color: '#00b8d1' }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color={theme.palette.text.primary}>
                  {t(listItem)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {children}
    </Item>
  );
};

export default PlanOverview;
