import { Endpoints } from 'utils/endpoints';
import { VariablesGroup } from 'services/entities/VariablesEntity';
import { ProposalContentEntity } from 'services/entities/ProposalEntity';
import { FontEntity } from '../../entities/FontsEntity';
import { apiClient } from 'services/api';
import { editorClient } from 'services/api/client';
import type { EditorRepository, UpdateDocumentStatusPayload, UpdateDocumentStatusResponse } from '../interfaces/EditorRepository';

interface Content {
  id: string;
}

interface CreateContentResponse {
  content: Content;
}

interface CopyContentParams {
  fromContentId: string;
  toContentId: string;
}

export class ApiEditorRepository implements EditorRepository {
  public async getVariables(contentId: string): Promise<VariablesGroup> {
    return await editorClient.get<VariablesGroup>(`/content/${contentId}/variables`);
  }

  public async getProposalContent(proposalId: string): Promise<ProposalContentEntity> {
    const data = await apiClient.get<ProposalContentEntity>(Endpoints.getProposalContent(proposalId));

    if (!data) {
      throw new Error('Response was not ok');
    }
    return data;
  }

  public async getInstalledFonts(contentId: string): Promise<FontEntity[]> {
    const data = await apiClient.get<FontEntity[]>(Endpoints.getInstalledFonts(contentId));
    return data;
  }

  public async createContent(type: string): Promise<Content> {
    const data = await editorClient.post<CreateContentResponse>('/content', { type });
    return data.content;
  }

  public async patchContent({ contentId, ...payload }): Promise<Content> {
    const data = await editorClient.patch<CreateContentResponse>(`/content/${contentId}`, { ...payload });
    return data.content;
  }

  public async copyContent({ fromContentId, toContentId }: CopyContentParams): Promise<Content> {
    const data = await editorClient.post<CreateContentResponse>(`/content/${fromContentId}/copy`, { to_content_id: toContentId });
    return data.content;
  }

  public async updateDocumentStatus(documentId: string, payload: UpdateDocumentStatusPayload): Promise<UpdateDocumentStatusResponse> {
    await editorClient.post(`/document/${documentId}/status`, payload);
    return { status: payload.status };
  }

  public async voidDocument(documentId: string): Promise<void> {
    await editorClient.post(`/document/${documentId}/void`, null);
  }
}
