import { Endpoints } from '../../../utils/endpoints';
import { AccountRepository } from './AccountRepository';
import { apiClient } from 'services/api';
import {
  AccountApiResponse,
  PausedAccountEntity,
  BillingEntity,
  BillingOptionEntity,
  BillingOptionsEntity,
  BillingInfoApiResponse,
  UpdateBillingInfoApiResponse,
  BillingOptionsApiResponse,
  CouponValidateApiResponse,
  BillingOptionPreviewChangesEntity,
  GetBillingOptionsParam,
  UpdateBillingParam,
  SubscriptionEntity,
  SubscriptionApiResponse,
} from 'services/api/account/interfaces';
import { AxiosError } from 'axios';

export class ApiAccountRepository implements AccountRepository {
  public async getAccountPaused(): Promise<PausedAccountEntity> {
    const { paused } = await apiClient.get<AccountApiResponse>(Endpoints.getCurrentAccount);

    return {
      paused: paused === 1,
    };
  }

  public async getSubscription(accountId: number): Promise<SubscriptionEntity> {
    const response = await apiClient.get<SubscriptionApiResponse>(Endpoints.getSubscription(accountId));
    const { uuid, state, current_period_ends_at } = response;

    let currentPeriodEndsAt: { date: string; timeZone: string; timeZoneType: number } | null = null;
    if (current_period_ends_at) {
      currentPeriodEndsAt = {
        date: current_period_ends_at.date,
        timeZone: current_period_ends_at.timezone,
        timeZoneType: current_period_ends_at.timezone_type,
      };
    }

    return {
      uuid,
      state,
      currentPeriodEndsAt,
    };
  }

  public async getBillingInfo(accountId: number): Promise<BillingEntity> {
    const {
      billing_address_1,
      billing_address_2,
      billing_city,
      billing_state,
      billing_country,
      billing_zip,
      last_four,
      cc_type,
      cc_first_name,
      cc_last_name,
      cc_month,
      cc_year,
    } = await apiClient.get<BillingInfoApiResponse>(Endpoints.getBillingInfo(accountId));

    return {
      address1: billing_address_1,
      address2: billing_address_2,
      city: billing_city,
      state: billing_state,
      country: billing_country,
      zip: billing_zip,
      lastFour: Number(last_four),
      cardType: cc_type,
      firstName: cc_first_name,
      lastName: cc_last_name,
      expiryMonth: Number(cc_month),
      expiryYear: Number(cc_year),
    };
  }

  public async updateBillingInfo({ accountId, payload }: UpdateBillingParam): Promise<UpdateBillingInfoApiResponse> {
    try {
      const response = await apiClient.patch<UpdateBillingInfoApiResponse>(Endpoints.updateBillingInfo(accountId), payload);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data
        ? (err.response?.data as { status: string; message: string })
        : { status: 'error', message: 'Server error' };
      return { status: data.status || 'error', message: data.message };
    }
  }

  public async getBillingOptions({ accountId, planName, promoCode }: GetBillingOptionsParam): Promise<BillingOptionsEntity> {
    const response = await apiClient.get<BillingOptionsApiResponse>(Endpoints.getBillingOptions(accountId), {
      params: { plan: planName, coupon: promoCode },
    });
    return this.formatBillingOptions(response);
  }

  private formatBillingOptions(options: BillingOptionsApiResponse): BillingOptionsEntity {
    const formattedOptions = new Array<BillingOptionEntity>();
    options.forEach((option) => {
      let preview: BillingOptionPreviewChangesEntity | null = null;
      if (option.preview_change_data) {
        preview = {
          monthlySeatPrice: option.preview_change_data.monthly_seat_price,
          newSeats: option.preview_change_data.new_seats,
          isCustom: !!option.preview_change_data.is_custom,
          freeSeats: option.preview_change_data.free_seats,
          paidSeats: option.preview_change_data.paid_seats,
          totalSeats: option.preview_change_data.total_seats,
          monthlyTotal: option.preview_change_data.monthly_total,
          termNumber: option.preview_change_data.term_number,
          recurringTotal: option.preview_change_data.recurring_total,
          chargeTotal: option.preview_change_data.charge_total,
          creditUsed: option.preview_change_data.credit_used,
          billedNow: option.preview_change_data.billed_now,
          renewalDate: option.preview_change_data.renewal_date,
          hasCharge: option.preview_change_data.has_charge,
          showCCLink: option.preview_change_data.show_cc_link,
          showManageSeats: option.preview_change_data.show_manage_seats,
          localTaxesIncluded: option.preview_change_data.local_taxes_included,
          percentageOff: option.preview_change_data.percentage_off,
          setupFee: option.preview_change_data.setup_fee,
          discountTotal: option.preview_change_data.discount,
          manuallyCalculated: option.preview_change_data.manually_calculated,
          couponApplied: option.preview_change_data.coupon_applied,
          annualDiscount: option.preview_change_data.annual_discount || null,
          proration: option.preview_change_data.proration,
        };
      }
      formattedOptions.push({
        name: option.bi_label_name,
        planId: option.id,
        planName: option.name,
        price: option.price,
        monthlyValue: option.monthly_value,
        priceBySeat: option.per_user,
        userLimit: option.user_limit,
        isCurrentPlan: option.is_current_plan,
        changePreview: preview,
      });
    });
    return formattedOptions;
  }

  public async validateCoupon(accountId: number, couponCode: string): Promise<CouponValidateApiResponse> {
    const options = { params: { promo_code: couponCode } };
    try {
      const response = await apiClient.get<CouponValidateApiResponse>(Endpoints.validateCoupon(accountId), options);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: string; code: string }) : { state: 'error', code: '' };
      return { state: data.state, code: data.code };
    }
  }
}
