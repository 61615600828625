import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { RootState } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { setSaveStatus, setSaveStatusBasedOnApiResponse } from '../grid/reduxStore/saveStatusSlice';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';
import {
  loadBlockStyleSettings,
  addBlockSettings,
  deleteBlockSettings,
  updateBlockSettings,
  setBlockBackgroundColor,
  setBlockBorderColor,
  setBorderRadius,
  setBorderWidth,
  setPaddingValues,
  resetAllPanels,
} from '../grid/reduxStore/blockStyleSettingsSlice';
import { BlockEvents, DefaultSocketResponseType } from '../../../services/socket/SocketEvents';
import { SectionContentType } from '../Sections/Section.model';
import { BlockSides } from '../GridDndEditor/Block/Helper/BlockSides';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';

export interface BlockSettings {
  updatedByUserId: number;
  blockId: string;
  borderStyle?: string;
  borderLeft?: number;
  borderRight?: number;
  borderTop?: number;
  borderBottom?: number;
  borderRadius?: number;
  borderColor?: string;
  backgroundColor?: string;
  opacity?: number;
  imageAlt?: string;
  imageLink?: string;
  [type: string]: any;
}

const initialBorderSettings = {
  borderLeft: 0,
  borderRight: 0,
  borderTop: 0,
  borderBottom: 0,
};

export const defaultBlockStyle: BlockSettings = {
  blockId: '',
  updatedByUserId: 0,
  borderRadius: 0,
  borderColor: '#ffffffff',
  backgroundColor: '#ffffff00',
  opacity: 100,
  imageAlt: '',
  imageLink: '',
  borderWidth: 0,
  currentBorderSide: [BlockSides.ALL_SIDES],
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  currentTimestamp: '',
  ...initialBorderSettings,
};

export const useBlockStyleSettings = () => {
  const { data: currentUser } = useCurrentUser();
  const { socketClient } = useSocketClient();
  const userId = currentUser.id;

  const initialBlockStyle = { ...defaultBlockStyle, updatedByUserId: userId };
  const { selectedBlockIdByWrapper, selectedBlockIdByIcon } = useContext(SelectionContext);
  const blockId = selectedBlockIdByIcon as string;
  const selectedTableBlockId = selectedBlockIdByWrapper as string;

  const dispatch = useDispatch();
  const blocksSettings = useSelector((state: RootState) => state.blockStyleSettings);
  const getBlockStyle = () => {
    if (blocksSettings.blockStyle[blockId]) {
      return blocksSettings.blockStyle[blockId];
    }
    return { ...initialBlockStyle };
  };
  const localStyle = getBlockStyle();

  // Table block side panel is active different than other blocks. Therefore, we need to get id by selectedBlockIdByWrapper instead of selectedBlockIdByIcon
  useEffect(() => {
    !selectedTableBlockId && dispatch(resetAllPanels());
  }, [selectedTableBlockId]);

  const getActiveBorderSides = (localStyle: BlockSettings, borderWidth: number) => {
    const blockSettings = { ...localStyle };
    const activeBorderSides = blockSettings.currentBorderSide ?? [BlockSides.ALL_SIDES];
    if (activeBorderSides.includes(BlockSides.LEFT) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      blockSettings.borderLeft = borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.RIGHT) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      blockSettings.borderRight = borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.TOP) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      blockSettings.borderTop = borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.BOTTOM) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      blockSettings.borderBottom = borderWidth;
    }
    return { ...blockSettings, borderWidth };
  };

  const displayChange = (blockId, { opacity, imageAlt, imageLink }) => {
    if (!blockId) return;

    const displayStyles = { opacity, imageAlt, imageLink };

    const updatedStyles = {
      ...getBlockStyle(),
      ...displayStyles,
    };

    updateBlockStyle(blockId, updatedStyles);
    saveCurrentBlockStyle(updatedStyles);
  };

  const loadBlockStyles = (sections: SectionContentType[]) => {
    dispatch(loadBlockStyleSettings({ sections, defaultBlockStyle }));
  };

  const addBlock = (blockId: string | null) => {
    if (blockId && !blocksSettings.blockStyle[blockId]) {
      const addedBlockSettings = {
        ...defaultBlockStyle,
        blockId,
      };

      const payload = { blockId: blockId || '', blockSettings: addedBlockSettings };
      dispatch(addBlockSettings(payload));
    }
  };

  const deleteBlock = (blockId: string | null) => {
    blockId && dispatch(deleteBlockSettings({ blockId }));
  };

  const updateBlockStyle = (blockId: string | null, blockSettings) => {
    blockId && dispatch(updateBlockSettings({ blockId, blockSettings }));
  };

  const changeBlockBackgroundColor = (color: string) => {
    const payload = { blockId: blockId || '', backgroundColor: color };
    if (blockId) {
      const localStyle = getBlockStyle();
      dispatch(setBlockBackgroundColor(payload));
      saveCurrentBlockStyle({ ...localStyle, ...payload });
    }
  };

  const changeBlockBorderColor = (color: string) => {
    const payload = { blockId: blockId || '', borderColor: color };
    if (blockId) {
      const localStyle = getBlockStyle();
      dispatch(setBlockBorderColor(payload));
      saveCurrentBlockStyle({ ...localStyle, ...payload });
    }
  };

  const changeBlockBorderSide = (sides: string[]) => {
    if (!blockId) return;

    let updatedCurrentBlockStyle: BlockSettings = { ...blocksSettings.blockStyle[blockId] };
    const { borderWidth } = updatedCurrentBlockStyle;
    const activeBorderSettings = {};

    if (!sides.length) {
      updatedCurrentBlockStyle = { ...updatedCurrentBlockStyle, ...initialBorderSettings };
    } else {
      sides.forEach((side: string) => {
        const borderName = `border${side}`;
        activeBorderSettings[borderName] = borderWidth;
      });
      updatedCurrentBlockStyle = { ...getBlockStyle(), ...initialBorderSettings, ...activeBorderSettings };
    }
    updatedCurrentBlockStyle.currentBorderSide = sides;
    updateBlockStyle(blockId, updatedCurrentBlockStyle);
    saveCurrentBlockStyle({ ...updatedCurrentBlockStyle });
  };

  const changeBorderRadius = (radius: number) => {
    const payload = { blockId: blockId || '', borderRadius: radius };
    if (blockId) {
      const localStyle = getBlockStyle();
      dispatch(setBorderRadius(payload));
      saveCurrentBlockStyle({ ...localStyle, ...payload });
    }
  };

  const changeBorderWidth = (width: number) => {
    const updatedStyle: Partial<BlockSettings> = getActiveBorderSides(getBlockStyle(), width);
    const payload = { blockId: blockId || '', updatedStyle };
    if (blockId) {
      dispatch(setBorderWidth(payload));
      saveCurrentBlockStyle({ ...localStyle, ...updatedStyle });
    }
  };

  const changePadding = (blockId: string | null, paddingStyle) => {
    const payload = { blockId: blockId || '', paddingStyle };
    if (blockId) {
      dispatch(setPaddingValues(payload));
      saveCurrentBlockStyle({ ...localStyle, ...paddingStyle });
    }
  };

  const saveCurrentBlockStyle = (style: BlockSettings) => {
    const styleToSave = { ...style };
    delete styleToSave.borderWidth;
    delete styleToSave.currentBorderSide;
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    socketClient.publish(BlockEvents.BLOCK_SETTINGS_SAVE, styleToSave, (responseParsed: DefaultSocketResponseType) => {
      dispatch(setSaveStatusBasedOnApiResponse({ status: responseParsed.status, errorCode: responseParsed.errorCode }));
      return;
    });
  };

  return {
    getBlockStyle,
    loadBlockStyles,
    addBlock,
    deleteBlock,
    updateBlockStyle,
    changeBlockBackgroundColor,
    changeBlockBorderColor,
    changeBlockBorderSide,
    changeBorderRadius,
    changeBorderWidth,
    changePadding,
    displayChange,
    saveCurrentBlockStyle,
  };
};
