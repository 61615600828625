import { useCallback, useContext } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { Card, CardHeader, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCCTypeFromString, getIconFromCCType } from '../billing/utils';

const PaymentMethodSummary = () => {
  const { t } = useTranslation();
  const { isBillingFetched, billingData, isBillingRefetching } = useContext(PlanSummaryContext);

  const getIcon = useCallback(() => {
    const cardName = billingData?.cardType || '';
    return getIconFromCCType(getCCTypeFromString(cardName));
  }, [billingData?.cardType]);

  if (!isBillingFetched || isBillingRefetching) {
    return <Skeleton width="100%" height="25px" variant="rounded" />;
  }

  if (!billingData || !billingData.lastFour) {
    return <Typography variant="body1">{t('settings.billing.no_card_on_file')}</Typography>;
  }

  return (
    <Card variant="outlined" sx={{ p: 0, width: '100%', border: 'none', '& .MuiCardHeader-avatar': { marginRight: 1 } }}>
      <CardHeader
        disableTypography
        sx={{ p: 0 }}
        title={
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <img src={getIcon()} style={{ height: '25px' }} />
            <Typography variant="body1" sx={{ ml: 1, mr: '61px' }}>
              {t('settings.billing.card_type', {
                last4Digits: billingData.lastFour,
              })}
            </Typography>
            <Typography variant="body1">
              {t('settings.billing.expires', {
                expiryDate: `${billingData.expiryMonth} / ${billingData.expiryYear}`,
                interpolation: { escapeValue: false },
              })}
            </Typography>
          </Stack>
        }
      />
    </Card>
  );
};

export default PaymentMethodSummary;
