import { PlanTier, PlanTierButtonConfigProps } from '../plan/interface';

export const availableTiers: PlanTier[] = ['basic', 'team', 'business'];
export const allTiers: PlanTier[] = ['', 'free', 'basic', 'team', 'business', 'employee'];

export const getPlanTier = (planData: any): PlanTier => {
  if (!planData || !planData.name) {
    return planData?.custom ? <PlanTier>'business' : <PlanTier>'';
  }
  const foundTier = planData.type !== '' ? getPlanTierByString(planData.type) : getPlanTierByString(planData.name.toLowerCase());
  return foundTier === '' && planData.custom ? <PlanTier>'business' : foundTier;
};

export const getPlanTierByString = (planName: string): PlanTier => {
  const foundTier = allTiers.find((tier) => <PlanTier>planName.toLowerCase() === tier);
  if (!foundTier) {
    return '';
  } else if (foundTier === 'employee') {
    return 'business';
  }
  return foundTier;
};

export const isPlanUpgrade = (planName: PlanTier, curTier: PlanTier): boolean => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const newPlanPosition = allTiers.indexOf(planName);

  return newPlanPosition > curPlanPosition;
};

export const canChangeToPlan = (planName: PlanTier, curTier: PlanTier, canDowngrade = true): boolean => {
  if (curTier === planName) {
    return false;
  }
  return !isPlanUpgrade(planName, curTier) && !canDowngrade ? false : true;
};

export const getPlanButtonLabel = (planName: PlanTier, curTier: PlanTier, options?: PlanTierButtonConfigProps): string => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const buttonPlanPosition = allTiers.indexOf(planName);

  if (curPlanPosition === buttonPlanPosition) {
    return options?.current || 'settings.plan.button_current_plan_label';
  }

  return curPlanPosition > buttonPlanPosition
    ? options?.downgrade || 'settings.plan.button_downgrade_plan_label'
    : options?.upgrade || 'settings.plan.button_upgrade_plan_label';
};

export const formatAsPrice = (price: number, hideDecimal = false, options = {}) => {
  const defaultDigits = {
    minimumFractionDigits: !hideDecimal || price % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  };
  const opts = { style: 'currency', currency: 'USD', ...defaultDigits, ...options };

  return price.toLocaleString('en-US', opts);
};

const cards: { name: string; lengths: number[]; prefixes: number[]; checkdigit: boolean }[] = [
  {
    name: 'Visa',
    lengths: [13, 16],
    prefixes: [4],
    checkdigit: true,
  },
  {
    name: 'MasterCard',
    lengths: [16],
    prefixes: [5],
    checkdigit: true,
  },
  {
    name: 'American Express',
    lengths: [15],
    prefixes: [3],
    checkdigit: true,
  },
];

interface CardValidationResult {
  state: boolean;
  message: string;
}

export const validateCreditCard = (cardNumber: string): CardValidationResult => {
  if (cardNumber.length == 0) {
    return {
      state: false,
      message: 'No card number provided',
    };
  }

  if (cardNumber.charAt(0) === '*') {
    return { state: true, message: 'Not updating credit card number' };
  }

  const cardType = cards.find((c) => c.prefixes.indexOf(Number(cardNumber.charAt(0))) > -1);
  if (!cardType) {
    return {
      state: false,
      message: 'Unknown card type',
    };
  }

  cardNumber = cardNumber.replace(/\s/g, '');
  const cardNo = cardNumber;
  const cardexp = /^[0-9]{13,19}$/;
  if (!cardexp.exec(cardNo)) {
    return {
      state: false,
      message: 'Credit card number is in invalid format',
    };
  }

  let lengthValid = false;
  for (let j = 0; j < cardType.lengths.length; j++) {
    if (cardNo.length == cardType.lengths[j]) {
      lengthValid = true;
      break;
    }
  }

  if (!lengthValid) {
    return {
      state: false,
      message: 'Credit card number has an inappropriate number of digits',
    };
  }

  return {
    state: true,
    message: 'Card valid',
  };
};

export const parseUnformattedApiErrors = (errorString: string): { field: string | null; message: string }[] => {
  const newApiErrors: { field: string | null; message: string }[] = [];
  errorString.split(/\r?\n/).map((err) => {
    let parsedErr = err.replaceAll(/(<([^>]+)>)/gi, '');
    parsedErr = parsedErr.replaceAll(/\r?\n/gi, '').trim();
    if (parsedErr !== '') {
      if (err.toLowerCase().indexOf('credit card number') > -1) {
        newApiErrors.push({ field: 'ccNumber', message: 'settings.billing.payment_method_form.invalid_credit_card' });
      } else if (err.toLowerCase().indexOf('verification value') > -1) {
        newApiErrors.push({ field: 'securityCode', message: 'settings.billing.payment_method_form.invalid_security_code' });
      } else if (err.toLowerCase().indexOf('expiry') > -1) {
        newApiErrors.push({ field: 'securityCode', message: 'settings.billing.payment_method_form.invalid_expiry_date' });
      } else {
        newApiErrors.push({ field: null, message: parsedErr });
      }
    }
  });

  return newApiErrors;
};
