import SettingsTheme from 'muiTheme/SettingsTheme';
import BillingHeader from '../plan/billingHeader';
import { Box, Skeleton, Snackbar, Stack, ThemeProvider } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';
import { useTranslation } from 'react-i18next';
import './styles.less';
import PaymentMethodModal from './PaymentMethodModal';
import { useState } from 'react';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { useCurrentUser } from 'providers/UserProvider';
import { PlanSummaryProvider } from './PlanSummaryProvider';
import { PlanRepository } from 'services/api/plan/PlanRepository';
import PaymentMethodSummary from './PaymentMethodSummary';

interface PlanSummaryPageProps {
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  accountRepository: AccountRepository;
}

const PlanSummaryPage = ({ planRepository, listingsRepository, accountRepository }: PlanSummaryPageProps) => {
  const { t } = useTranslation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState<boolean>(false);

  const {
    data: { accountId },
  } = useCurrentUser();

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <PlanSummaryProvider
          accountId={accountId}
          accountRepository={accountRepository}
          planRepository={planRepository}
          listingsRepository={listingsRepository}
          setApiErrors={setApiErrors}
        >
          <Box>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              message={apiErrors.length > 0 ? apiErrors[0] : ''}
              autoHideDuration={6000}
              onClose={() => setApiErrors(apiErrors.length > 1 ? [...apiErrors.slice(1)] : [])}
              open={apiErrors.length > 0}
            />
            {isPaymentMethodOpen && <PaymentMethodModal isOpen={isPaymentMethodOpen} setIsOpen={setIsPaymentMethodOpen} />}
            <Box>
              <BillingHeader />
            </Box>
            <Stack direction="column" sx={{ my: 3 }} gap={3}>
              <BillingCard>
                <BillingCardHeader
                  title={t('settings.summary.your_subscription')}
                  actions={[
                    {
                      label: t('settings.summary.cancel_subscription'),
                      // eslint-disable-next-line no-console
                      onClick: () => console.log('cancel-subscription-action'),
                      variant: 'text',
                      color: 'primary',
                    },
                    {
                      label: t('settings.summary.manage_subscription'),
                      // eslint-disable-next-line no-console
                      onClick: () => console.log('manage-subscription-action'),
                    },
                  ]}
                />
                <BillingCardContent>
                  <Skeleton width="100%" height="144px" variant="rounded" />
                </BillingCardContent>
              </BillingCard>

              <BillingCard>
                <BillingCardHeader
                  title={t('settings.summary.billing_info')}
                  actions={[
                    {
                      label: t('settings.summary.update_billing_info'),
                      onClick: () => setIsPaymentMethodOpen(true),
                    },
                  ]}
                />
                <BillingCardContent>
                  <PaymentMethodSummary />
                </BillingCardContent>
              </BillingCard>

              <BillingCard>
                <BillingCardHeader
                  title={t('settings.summary.billing_contact')}
                  actions={[
                    {
                      label: t('settings.summary.update_billing_contact'),
                      // eslint-disable-next-line no-console
                      onClick: () => console.log('update-billing-contact-action'),
                    },
                  ]}
                />
                <BillingCardContent>
                  <Skeleton width="100%" height="40px" variant="rounded" />
                </BillingCardContent>
              </BillingCard>

              <BillingCard>
                <BillingCardHeader
                  title={t('settings.summary.document_open_usage')}
                  tooltip={t('settings.summary.document_opens_tooltip')}
                  subheader="July 1 - July 31"
                />
                <BillingCardContent>
                  <Skeleton width="100%" height="40px" variant="rounded" />
                  <Skeleton width="30%" height="40px" variant="rounded" />
                </BillingCardContent>
              </BillingCard>

              <BillingCard>
                <BillingCardHeader title={t('settings.summary.invoice_history')} />
                <BillingCardContent sx={{ gap: 2, width: '100%' }}>
                  <Stack direction="row" gap="4px" justifyContent="space-between" width="100%">
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="auto" height="40px" variant="rounded" sx={{ flexGrow: 4 }} />
                    <Skeleton width="60px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                  </Stack>
                  <Stack direction="row" gap="4px" justifyContent="space-between" width="100%">
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="auto" height="40px" variant="rounded" sx={{ flexGrow: 4 }} />
                    <Skeleton width="60px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                  </Stack>
                  <Stack direction="row" gap="4px" justifyContent="space-between" width="100%">
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="auto" height="40px" variant="rounded" sx={{ flexGrow: 4 }} />
                    <Skeleton width="60px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                  </Stack>
                  <Stack direction="row" gap="4px" justifyContent="space-between" width="100%">
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="auto" height="40px" variant="rounded" sx={{ flexGrow: 4 }} />
                    <Skeleton width="60px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                    <Skeleton width="40px" height="40px" variant="rounded" />
                  </Stack>
                </BillingCardContent>
              </BillingCard>
            </Stack>
          </Box>
        </PlanSummaryProvider>
      </ThemeProvider>
    </PageLoader>
  );
};

export default PlanSummaryPage;
