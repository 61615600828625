import { ReactNode, useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AuthenticatedLayoutRoute } from 'routing/AuthenticatedRoutes';

import PageTemplateWrapper from 'components/templates/pageTemplate';
import { Integrations } from 'pages/integrations/interface';
import {
  GongPage,
  IntegrationsPage,
  PipelinePage,
  NewDocumentPage,
  EditorPage,
  PreviewGridPage,
  ProposalSharePage,
  OptionalFeaturesPage,
  SsoPage,
  SsoCreate,
  SsoProviderUpdate,
  PlanPage,
  PlanSummaryPage,
  IntegrationsOAuth2Page,
  SalesforceCrmPage,
  DocumentCreatePage,
  WorkspacesPage,
  WorkspacesSwitcher,
  LibraryPage,
  ConnectAPIPage,
  TemplateEditorPage,
  BillingPage,
  PrismaticPage,
  PrismaticAutomationsPage,
} from 'pages';
import { ApiUserIntegrationRepository } from 'services/integrations/repositories/implementations/ApiUserIntegrationRepository';
import { ApiPlanRepository } from 'services/api/plan/ApiPlanRepository';
import CinderRedirect from 'components/cinderredirect';
import { IntegrationsLayoutRoute } from 'routing/IntegrationsLayoutRoute';
import { SettingsPlanLayoutRoute } from 'routing/SettingsPlanLayoutRoute';
import { EvaluateFeatureFlags as EvaluateFeatureFlagsBase } from 'routing/EvaluateFeatureFlags';
import goToCinder from 'utils/goToCinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import { EditorLayoutRoute } from './layouts/EditorLayoutRoute';
import PageLoader from 'components/page-loader/page-loader';
import { LicenseInfo } from '@mui/x-license';
import { ApiAccountRepository } from 'services/api/account/ApiAccountRepository';
import { ApiListingsRepository } from 'services/api/listings/ApiListingsRepository';
import { ApiPlanFeatureRepository } from 'services/repositories/implementations/ApiPlanFeatureRepository';

const apiUserIntegrationsRepository = new ApiUserIntegrationRepository();
const apiPlanRepository = new ApiPlanRepository();
const apiAccountRepository = new ApiAccountRepository();
const apiListingsRepository = new ApiListingsRepository();
const apiPlanFeatureRepository = new ApiPlanFeatureRepository();

const goToCinderDashboard = () => goToCinder(CINDER_URLS.dashboard);

const datagridLicenceKey = process.env.REACT_APP_DATAGRID_PRO_LICENCE_KEY || undefined;
LicenseInfo.setLicenseKey(datagridLicenceKey || '');

const EvaluateFeatureFlags = ({ flags, children }: { flags: string[]; children: ReactNode }) => (
  <EvaluateFeatureFlagsBase
    loadingIndicator={<PageLoader isLoading isLoaded={false} />}
    flags={flags}
    onConditionFailed={goToCinderDashboard}
  >
    {children}
  </EvaluateFeatureFlagsBase>
);

const NavigateToCinderDashboard = () => {
  useEffect(() => {
    goToCinderDashboard();
  }, []);
  return null;
};

export function AppRoutes() {
  return (
    <Routes>
      <Route index element={<NavigateToCinderDashboard />} />
      <Route path="/" element={<AuthenticatedLayoutRoute />}>
        <Route element={<PageTemplateWrapper />}>
          <Route path="integrations" element={<IntegrationsLayoutRoute />}>
            <Route index element={<IntegrationsPage apiUserIntegrationsRepository={apiUserIntegrationsRepository} />} />
            <Route
              path={Integrations.GONG.identifier}
              element={<GongPage apiUserIntegrationsRepository={apiUserIntegrationsRepository} />}
            />
            <Route path="design" element={<PrismaticPage />} />
            <Route path="automations" element={<PrismaticAutomationsPage />} />
          </Route>
          <Route
            path="oauth/:integrationName"
            element={<IntegrationsOAuth2Page apiUserIntegrationsRepository={apiUserIntegrationsRepository} />}
          />
          <Route path="settings" element={<Outlet />}>
            <Route index element={<CinderRedirect />} />
            <Route
              path="billing/:planName"
              element={
                <BillingPage
                  accountRepository={apiAccountRepository}
                  planRepository={apiPlanRepository}
                  listingsRepository={apiListingsRepository}
                />
              }
            />
            <Route element={<SettingsPlanLayoutRoute />}>
              <Route
                path="plan"
                element={<PlanPage planRepository={apiPlanRepository} planFeatureRepository={apiPlanFeatureRepository} />}
              />
              <Route
                path="summary"
                element={
                  <EvaluateFeatureFlags flags={[FeatureFlags.planSummaryPage]}>
                    <PlanSummaryPage
                      planRepository={apiPlanRepository}
                      listingsRepository={apiListingsRepository}
                      accountRepository={apiAccountRepository}
                    />
                  </EvaluateFeatureFlags>
                }
              />
            </Route>
            <Route path="connect-api" element={<ConnectAPIPage />} />
            <Route path="optional-features" element={<OptionalFeaturesPage />} />
            <Route
              path="sso"
              element={
                <EvaluateFeatureFlags flags={[FeatureFlags.sso]}>
                  <Outlet />
                </EvaluateFeatureFlags>
              }
            >
              <Route index element={<SsoPage />} />
              <Route path="configuration" element={<Outlet />}>
                <Route index element={<SsoCreate />} />
                <Route path=":providerId" element={<SsoProviderUpdate />} />
              </Route>
            </Route>
            <Route
              path="workspaces"
              element={
                <EvaluateFeatureFlags flags={[FeatureFlags.multiaccounts]}>
                  <Outlet />
                </EvaluateFeatureFlags>
              }
            >
              <Route index element={<WorkspacesPage />} />
            </Route>
          </Route>
          <Route
            path="workspaces/switcher"
            element={
              <EvaluateFeatureFlags flags={[FeatureFlags.multiaccounts]}>
                <Outlet />
              </EvaluateFeatureFlags>
            }
          >
            <Route index element={<WorkspacesSwitcher />} />
          </Route>
          <Route
            path="crm"
            element={
              <EvaluateFeatureFlags flags={[FeatureFlags.crmDataFlexibility]}>
                <Outlet />
              </EvaluateFeatureFlags>
            }
          >
            <Route path="salesforce" element={<SalesforceCrmPage />} />
            <Route path="document-create" element={<DocumentCreatePage />} />
          </Route>
          <Route
            path="proposals/share/:proposalId"
            element={
              <EvaluateFeatureFlags flags={[FeatureFlags.newShareExperience]}>
                <ProposalSharePage />
              </EvaluateFeatureFlags>
            }
          />
          <Route
            path="pipeline"
            element={
              <EvaluateFeatureFlags flags={[FeatureFlags.editorV3, FeatureFlags.documentsV3]}>
                <PipelinePage />
              </EvaluateFeatureFlags>
            }
          />
          <Route path="library" element={<LibraryPage />} />
        </Route>
        <Route
          path="editor"
          element={
            <EvaluateFeatureFlags flags={[FeatureFlags.editorV3]}>
              <EditorLayoutRoute />
            </EvaluateFeatureFlags>
          }
        >
          <Route index element={<NewDocumentPage />} />
          <Route path=":documentId" element={<EditorPage />} />
          <Route path="template/:assetId" element={<TemplateEditorPage />}></Route>
        </Route>
      </Route>
      <Route path="preview" element={<Outlet />}>
        <Route path=":documentId" element={<PreviewGridPage />} />
      </Route>
      <Route path="*" element={<CinderRedirect />} />
    </Routes>
  );
}
