import { Box, Button, DialogActions, InputAdornment, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  usePaymentMethodForm,
  SubmitHandler,
  transformPaymentMethodFieldValuesToApiPayload,
  PaymentMethodFormFieldValues,
} from '../utils/usePaymentMethodForm';
import FormField from '../utils/FormField';
import { CCInput, ExpiryDateInput, SecurityCodeInput } from '../billing/MaskedInputs';
import AEIcon from '../../../images/icons/icon-adornment-ae.png';
import MCIcon from '../../../images/icons/icon-adornment-mc.png';
import VisaIcon from '../../../images/icons/icon-adornment-visa.png';
import { useCallback, useContext, useEffect, useState } from 'react';
import { StateListing } from 'services/api/listings/interfaces';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { parseUnformattedApiErrors, validateCreditCard } from '../utils/utils';
import useUpdateBillingInfoMutation from 'hooks/useUpdateBillingInfoMutation';
import { BillingEntity } from 'services/api/account/interfaces';
import { getCCTypeFromCCNumber } from '../billing/utils';

interface PaymentMethodFormProps {
  onCancel: () => void;
  onSuccess: () => void;
  onFailure: () => void;
}

const getDefaultValues = (billingData: BillingEntity | undefined) => {
  return {
    cardholderName: billingData ? `${billingData.firstName} ${billingData.lastName}` : '',
    type: '',
    expiryDate: billingData ? `${billingData.expiryMonth}/${billingData.expiryYear.toString().substring(2)}` : '',
    securityCode: '',
    ccNumber: billingData?.lastFour ? `**** **** **** ${billingData.lastFour}` : '',
    addressLine1: billingData?.address1 || '',
    addressLine2: billingData?.address2 || '',
    city: billingData?.city || '',
    state: billingData?.state || '',
    zip: billingData?.zip || '',
    country: billingData?.country || '',
  };
};

const PaymentMethodForm = ({ onSuccess, onFailure, onCancel }: PaymentMethodFormProps) => {
  const [validProvinces, setValidProvinces] = useState<StateListing[]>([]);
  const { t } = useTranslation();

  const {
    accountId,
    billingData,
    isBillingFetched,
    locationData,
    isLocationFetched,
    getStatesFromCountry,
    setApiErrors,
    refetchBilling,
    isBillingRefetching,
  } = useContext(PlanSummaryContext);

  const { handleSubmit, control, watch, setValue, reset, setError, formState } = usePaymentMethodForm(getDefaultValues(billingData));

  const { updateBillingInfo, isLoading: isUpdating } = useUpdateBillingInfoMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      submitData.type = getCCTypeFromCCNumber(submitData.ccNumber);
      const payload = transformPaymentMethodFieldValuesToApiPayload(submitData);
      const response = await updateBillingInfo({ accountId: accountId, payload: payload });
      if (response.status !== 'error') {
        refetchBilling();
        onSuccess();
        return;
      }
      const parsedErrors = parseUnformattedApiErrors(response.message);
      const newApiErrors: string[] = [];
      parsedErrors.map((err) => {
        if (formState.defaultValues && err.field) {
          const key = err.field as keyof PaymentMethodFormFieldValues;
          if (key in formState.defaultValues) {
            setError(key, { message: t(err.message) });
            return;
          }
        }
        newApiErrors.push(t(err.message));
      }) || [];
      setApiErrors(newApiErrors);
      onFailure();
    },
    [transformPaymentMethodFieldValuesToApiPayload, updateBillingInfo, onSuccess, onFailure]
  );
  const watchCountry = watch('country', '');

  useEffect(() => {
    if (!!watchCountry && locationData?.countries && locationData?.states) {
      setValue('state', '');
      const provinces = getStatesFromCountry(watchCountry, locationData.states, locationData.countries);
      setValidProvinces(provinces);
      if (billingData?.state) {
        setValue('state', provinces.find((province) => province.abbr === billingData.state)?.abbr || '');
      } else {
        setValue('state', '');
      }
    }
  }, [watchCountry, isLocationFetched, billingData?.state]);

  useEffect(() => {
    if (reset) {
      reset(getDefaultValues(billingData));
    }
  }, [billingData, reset]);

  const getIsLoading = useCallback(() => {
    return !isLocationFetched || !isBillingFetched || isUpdating || !billingData || !locationData || isBillingRefetching;
  }, [isLocationFetched, isBillingFetched, isUpdating, billingData, locationData, isBillingRefetching]);

  return !isBillingFetched ? (
    <>
      <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
        <Skeleton variant="rounded" width="100%" height="40px" />
        <Stack direction="row" justifyContent="space-between" alignContent="center" gap={3}>
          <Skeleton variant="rounded" sx={{ flex: '1 0 0' }} width="45%" height="40px" />
          <Skeleton variant="rounded" sx={{ flex: '1 0 0' }} width="45%" height="40px" />
        </Stack>
        <Skeleton variant="rounded" width="100%" height="40px" />
        <Skeleton variant="rounded" width="100%" height="40px" />
        <Skeleton variant="rounded" width="100%" height="40px" />
        <Skeleton variant="rounded" width="100%" height="40px" />
        <Stack direction="row" justifyContent="space-between" alignContent="center" gap={3}>
          <Skeleton variant="rounded" sx={{ flex: '1 0 0' }} width="45%" height="40px" />
          <Skeleton variant="rounded" sx={{ flex: '1 0 0' }} width="45%" height="40px" />
        </Stack>
        <Skeleton variant="rounded" width="100%" height="40px" />
      </Stack>
      <Skeleton variant="rounded" width="60%" height="25px" sx={{ my: 3 }} />
      <DialogActions sx={{ padding: 0 }}>
        <Skeleton variant="rounded" width="75px" height="40px" />
        <Skeleton variant="rounded" width="125px" height="40px" />
      </DialogActions>
    </>
  ) : (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="ccNumber"
            label={t('settings.billing.payment_method_form.card_number')}
            placeholder={t('settings.billing.payment_method_form.card_number_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{
              required: 'This field is required',
              minLength: 13,
              validate: (value) => {
                const cardValidationResult = validateCreditCard(value);
                if (!cardValidationResult.state) {
                  return t('settings.billing.payment_method_form.invalid_credit_card');
                }
                return true;
              },
            }}
            onFocus={(e) => {
              if (e.target.value.charAt(0) === '*') {
                setValue('ccNumber', '', { shouldValidate: false });
              }
            }}
            helperText={t('settings.billing.payment_method_form.update_cc_required')}
            maskInputComponent={CCInput}
            adornments={
              <Stack direction="row">
                <InputAdornment position="end">
                  <img src={VisaIcon} />
                </InputAdornment>
                <InputAdornment position="end">
                  <img src={MCIcon} />
                </InputAdornment>
                <InputAdornment position="end">
                  <img src={AEIcon} />
                </InputAdornment>
              </Stack>
            }
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center" gap={3}>
          <FormField<PaymentMethodFormFieldValues>
            id="expiryDate"
            label={t('settings.billing.payment_method_form.expiry')}
            placeholder={t('settings.billing.payment_method_form.expiry')}
            control={control}
            shrinkLabel={true}
            rules={{
              required: 'This field is required',
              minLength: 4,
              validate: (value) => {
                const dateParts = value.split('/');
                if (dateParts.length !== 2) {
                  return t('settings.billing.payment_method_form.invalid_expiry');
                }
                const curYearStart = dayjs().format('YYYY').substring(0, 2);
                const validationDate = new Date(Number(`${curYearStart}${dateParts[1]}`), Number(dateParts[0]), 1);
                if (dayjs().isAfter(validationDate)) {
                  return t('settings.billing.payment_method_form.invalid_expiry');
                }
                return true;
              },
            }}
            maskInputComponent={ExpiryDateInput}
            disabled={getIsLoading()}
          />

          <FormField<PaymentMethodFormFieldValues>
            id="securityCode"
            label={t('settings.billing.payment_method_form.security_code')}
            placeholder={t('settings.billing.payment_method_form.security_code')}
            control={control}
            shrinkLabel={true}
            rules={{
              required: 'This field is required',
              minLength: 3,
              maxLength: 4,
              pattern: { value: /[0-9]/, message: t('settings.billing.payment_method_form.invalid_security_code') },
            }}
            maskInputComponent={SecurityCodeInput}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="cardholderName"
            label={t('settings.billing.payment_method_form.cardholder_name')}
            placeholder={t('settings.billing.payment_method_form.cardholder_name_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ required: 'This field is required', maxLength: 75 }}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="addressLine1"
            label={t('settings.billing.payment_method_form.address1')}
            placeholder={t('settings.billing.payment_method_form.address1_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ required: 'This field is required', maxLength: 125 }}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="addressLine2"
            label={t('settings.billing.payment_method_form.address2')}
            placeholder={t('settings.billing.payment_method_form.address2_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ maxLength: 125 }}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="city"
            label={t('settings.billing.payment_method_form.city')}
            placeholder={t('settings.billing.payment_method_form.city_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ required: 'This field is required', maxLength: 168 }}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" gap={3} justifyContent="space-between" alignContent="center">
          {validProvinces.length > 0 && (
            <FormField<PaymentMethodFormFieldValues>
              id="state"
              label={t('settings.billing.payment_method_form.province')}
              placeholder={t('settings.billing.payment_method_form.province_placeholder')}
              control={control}
              shrinkLabel={true}
              options={validProvinces.map((p) => {
                return {
                  id: p.abbr,
                  name: p.name,
                };
              })}
              disabled={getIsLoading()}
              rules={{ required: validProvinces.length > 0 && isLocationFetched ? 'This field is required' : false, maxLength: 168 }}
            />
          )}

          <FormField<PaymentMethodFormFieldValues>
            id="zip"
            label={t('settings.billing.payment_method_form.postal_code')}
            placeholder={t('settings.billing.payment_method_form.postal_code_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ required: 'This field is required', maxLength: 10 }}
            disabled={getIsLoading()}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <FormField<PaymentMethodFormFieldValues>
            id="country"
            label={t('settings.billing.payment_method_form.country')}
            placeholder={t('settings.billing.payment_method_form.country_placeholder')}
            control={control}
            shrinkLabel={true}
            rules={{ required: 'This field is required', maxLength: 168 }}
            options={
              locationData?.countries.map((c) => {
                return {
                  id: c.code,
                  name: c.name,
                };
              }) || []
            }
            disabled={getIsLoading()}
          />
        </Stack>
        <Typography variant="body2" color="text.primary">
          {t('settings.billing.payment_method_form.disclaimer')}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignContent="center" pt={3} gap={1}>
        <Button variant="text" size="medium" onClick={onCancel} disabled={isUpdating}>
          {t('settings.billing.payment_method_form.cancel')}
        </Button>
        <Button variant="contained" sx={{ borderRadius: 3 }} size="medium" onClick={handleSubmit(onSubmit)} disabled={getIsLoading()}>
          {isUpdating ? t('settings.billing.payment_method_form.saving') : t('settings.billing.payment_method_form.save_changes')}
        </Button>
      </Stack>
    </Box>
  );
};

export default PaymentMethodForm;
