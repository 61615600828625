import { TableType, TableTypeIdentifier } from '../../../grid/reduxStore/saveHandlers';
import { BlockContent, updateGridDimensionConfig, updateGridTableBlockState } from '../../../grid/reduxStore/editorSlice';
import { rootStore, useAppDispatch } from '../../../grid/reduxStore/Store';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { GridApiCommon, GridApiPro } from '@mui/x-data-grid-pro';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { setOfRowsForPricingTable } from './defaultData';

export const useTableManipulation = () => {
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const dispatch = useAppDispatch();

  const getBlockContent = (blockId: string, sectionId: string): BlockContent | null => {
    return { ...rootStore.getState().gridBlockReducer.sections[sectionId].blocksContent[blockId] };
  };

  const getTableData = (blockId: string, sectionId: string): TableType | undefined => {
    const blockContent = getBlockContent(blockId, sectionId);
    if (!blockContent) return undefined;
    return blockContent.contentTable;
  };

  const getTableUpdatedDimensions = (tableApi: GridApiPro | GridApiCommon) => {
    if (!tableApi || !tableApi.rootElementRef.current) return undefined;
    const visibleColumns = tableApi.getVisibleColumns();
    const columnsTotalWidth = visibleColumns.reduce((width, column) => width + (column.width ?? 0), 0);

    return {
      width: columnsTotalWidth,
      height: tableApi.rootElementRef.current.offsetHeight as number,
    };
  };

  const updateBlockDimensionsWithTableDimensions = async (blockId: string, sectionId: string, tableApi: GridApiPro | GridApiCommon) => {
    const dimensions = getTableUpdatedDimensions(tableApi);
    if (!dimensions) return;

    await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, { widthPx: dimensions.width, heightPx: dimensions.height });
  };

  const updateTableBlockContentStoreState = (blockId: string, sectionId: string, tableData: TableType) => {
    const payload = {
      sectionId: sectionId,
      blockId: blockId,
      contentTable: tableData,
    };
    return dispatch(updateGridTableBlockState(payload));
  };

  const updateTableBlockDimensionsStoreState = (
    blockId: string,
    sectionId: string,
    { width, height }: { width: number; height: number }
  ) => {
    dispatch(updateGridDimensionConfig({ sectionId, blockId, width, height }));
  };

  const toggleTableType = (blockId: string, sectionId: string) => {
    const tableContentData = getTableData(blockId, sectionId);
    if (!tableContentData) return;

    const { metadata, rows, columns } = tableContentData;
    const isPricingActive = metadata?.tableType === TableTypeIdentifier.PRICING_TABLE;

    const updatedRowsData = isPricingActive
      ? rows.filter((row) => row.rowType !== TableRowTypes(true)['TOTAL'])
      : [...rows, ...setOfRowsForPricingTable];

    return {
      rows: updatedRowsData,
      columns,
      metadata: {
        tableType: isPricingActive ? TableTypeIdentifier.TEXT_TABLE : TableTypeIdentifier.PRICING_TABLE,
      },
    };
  };

  return {
    getBlockContent,
    getTableData,
    getTableUpdatedDimensions,
    updateBlockDimensionsWithTableDimensions,
    updateTableBlockDimensionsStoreState,
    updateTableBlockContentStoreState,
    toggleTableType,
  };
};
